var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "clearflex" },
        [
          _c(
            "el-button",
            {
              staticClass: "fl-right",
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.relation },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-tree", {
        ref: "menuTree",
        attrs: {
          data: _vm.menuTreeData,
          "default-checked-keys": _vm.menuTreeIds,
          props: _vm.menuDefaultProps,
          "default-expand-all": "",
          "highlight-current": "",
          "node-key": "ID",
          "show-checkbox": "",
        },
        on: { check: _vm.nodeChange },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var node = ref.node
              var data = ref.data
              return _c("span", { staticClass: "custom-tree-node" }, [
                _c("span", [_vm._v(_vm._s(node.label))]),
                _vm._v(" "),
                _c(
                  "span",
                  [
                    _c(
                      "el-button",
                      {
                        style: {
                          color:
                            _vm.row.defaultRouter === data.name
                              ? "#E6A23C"
                              : "#85ce61",
                        },
                        attrs: {
                          type: "text",
                          size: "mini",
                          disabled: !node.checked,
                        },
                        on: {
                          click: function () {
                            return _vm.setDefault(data)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.row.defaultRouter === data.name
                                ? "首页"
                                : "设为首页"
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }